<template>
  <div>
    <div v-if="loading">
      <div class="d-flex justify-content-between mx-5 mt-3 mb-5">
        <div class="spinner-border text-success align-self-center loader-lg"/>
      </div>
    </div>
    <div v-else-if="loadingError" class="cm-loading-error">
      {{ $t("calendarModule.errors.loadingError") }}
    </div>
    <div v-else>
      <div class="cm-settings-line">
        <div></div>
        <div>
          <b-button-group size="sm">
            <b-button
              :pressed="calendarMode === 'week'"
              class="btn border-0 btn-sm mt-1 mb-1"
              :class="calendarMode === 'week' ? 'btn-primary' : 'btn-secondary'"
              @click="() => selectCalendarMode('day')"
            >
              {{ $t("calendarModule.calendarForm.texts.day") }}
            </b-button>
            <b-button
              :pressed="calendarMode !== 'week'"
              class="btn border-0 shadow-none btn-sm mt-1 mb-1"
              :class="calendarMode !== 'week' ? 'btn-primary' : 'btn-secondary'"
              @click="() => selectCalendarMode('week')"
            >
              {{ $t("calendarModule.calendarForm.texts.week") }}
            </b-button>
          </b-button-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as cmApi from "../../store/modules/calendarModule/calendarModule.api";

export default {
  name: "CalendarSettings",
  props: ["theCalendar", "calendarMode"],
  data() {
    return {
      cmApi: cmApi,
      loading: false,
      loadingError: false,
      calendar: null,
      defaultSlotsOpen: false,
      calendarFormOpen: false,
      dummyCalendarData: {
        _id: "be01508b-18b4-4d30-8412-00d08ceec90f",
        requiresPermission: false,
        defaultSlotsDaysBefore: 14,
        defaultSlotsExcludedDates: [],
        name: "Dummy Calendar",
        metadata: {
          color: "#73D8FF",
          aboveAddressText: "Anzeige Infotext Oberhalb",
          addressText: null,
          belowAddressText: "Anzeige Infotext Unterhalb",
          customFieldPermissions: null,
          isInactive: false
        },
        ownerId: "5af2a1f9-0cbe-4158-b53f-ae36b6c4ec7c",
        creationTimeStamp: "2021-06-23 06:13:16.839Z",
        updateTimeStamp: "2021-06-23 06:13:16.839Z",
        _v: 0
      },
      dummySlots: [
        {
          _id: "924f0f9c-3e28-472c-bccc-24ae089d8853",
          free: true,
          calendar: "be01508b-18b4-4d30-8412-00d08ceec90f",
          time: "12:00",
          endTime: "13:00",
          date: "2021-06-27",
          endDate: "2021-06-27",
          metadata: {
            slotType: "conversation",
            followupType: "sonnestPossible",
            extraDescription: "Extra Beschreibungstext",
            massUuid: "5f9612c4-ac9a-4130-b6cf-e1ebc2ea658f"
          },
          creationTimeStamp: "2021-06-23 06:20:42.806Z",
          updateTimeStamp: "2021-06-23 06:20:42.806Z"
        },
        {
          _id: "0f304172-de2e-494d-a4dc-5f39a87ac150",
          free: true,
          calendar: "be01508b-18b4-4d30-8412-00d08ceec90f",
          time: "13:00",
          endTime: "14:00",
          date: "2021-06-27",
          endDate: "2021-06-27",
          metadata: {
            slotType: "conversation",
            followupType: "sonnestPossible",
            extraDescription: "Extra Beschreibungstext",
            massUuid: "5f9612c4-ac9a-4130-b6cf-e1ebc2ea658f"
          },
          creationTimeStamp: "2021-06-23 06:20:42.844Z",
          updateTimeStamp: "2021-06-23 06:20:42.844Z"
        }
      ]
    };
  },
  computed: {
    calendarColorDotStyles() {
      const s = {};

      if (this.calendar) {
        s.backgroundColor = this.calendar.metadata.color;
      }

      return s;
    }
  },
  methods: {
    selectCalendarMode(mode) {
      this.$emit("changedCalendarMode", mode);
    },
  },
  created() {
    if (typeof this.theCalendar === "string") {
      this.calendar = null;
      this.calendar = this.dummyCalendarData;
    } else {
      this.calendar = this.theCalendar;
    }
  }
};
</script>
